import { useRef, useState, useEffect } from "react";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import { useMediaQuery } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

function TopBar() {
  const [userRole, setUserRole] = useState(null);
  const logoWidth = import.meta.env.VITE_API_WIDTH_TOPBAR;
  const { t } = useTranslation();
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserRole(decodedToken.role);
    }
  }, []);

  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);

  const btnRef4 = useRef(null);
  const btnRef5 = useRef(null);

  const isMobile = useMediaQuery("(max-width: 1000px)");

  return (
    <div
      className="  flex align-items-center justify-content-between relative lg:static"
      style={{ minHeight: "20px" }}
    >
      <img
        src={"/images/logoG.png"}
        style={{
          marginLeft: "0.2rem",
          width: logoWidth, // Directly applying width
          height: "auto", // Keep the height auto
        }}
        alt="logo"
      />
      <StyleClass
        nodeRef={btnRef1}
        selector="@next"
        enterClassName="hidden"
        leaveToClassName="hidden"
        hideOnOutsideClick
      >
        <a ref={btnRef1} className="cursor-pointer block lg:hidden text-700">
          <i className="pi pi-bars text-4xl"></i>
          <Ripple />
        </a>
      </StyleClass>
      <div
        className={`align-items-center flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full ${
          isMobile ? "surface-overlay" : ""
        } right-0 top-0 z-1 shadow-2 lg:shadow-none`}
      >
        <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          {userRole === 1 && (
            <li className="lg:relative">
              <StyleClass
                nodeRef={btnRef2}
                selector="@next"
                enterClassName="hidden"
                enterActiveClassName="scalein"
                leaveToClassName="hidden"
                leaveActiveClassName="fadeout"
                hideOnOutsideClick
              >
                <a
                  ref={btnRef2}
                  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round3 cursor-pointer transition-colors transition-duration-150 w-full"
                >
                  <i className="pi pi-shield mr-2"></i>
                  <span>SAFP</span>
                  <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                  <Ripple />
                </a>
              </StyleClass>
              <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round3 shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                <li>
                  <Link
                    to="/SAFPpendentes"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-hourglass mr-2"></i>
                    <span className="font-medium">{t("PENDING_REQUESTS")}</span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/SAFPhistorico"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-history mr-2"></i>
                    <span className="font-medium">
                      {t("HISTORIC_REQUESTS")}
                    </span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Trabalhadores"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-user-edit mr-2"></i>
                    <span className="font-medium">{t("WORKERS2")}</span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/ChefesSAFP"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-address-book mr-2"></i>
                    <span className="font-medium">{t("BOSS_SAFP")}</span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/Turnos"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-calendar-clock mr-2"></i>
                    <span className="font-medium">{t("SHIFTS")}</span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/HorarioDeRefeicao"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-clock mr-2"></i>
                    <span className="font-medium">{t("MEAL_HOURS")}</span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/Feriados"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-calendar-plus mr-2"></i>
                    <span className="font-medium">{t("HOLYDAY")}</span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/PontosSAFP"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-circle mr-2"></i>
                    <span className="font-medium">{t("Pontos")}</span>
                    <Ripple />
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {(userRole === 1 || userRole === 0) && (
            <li className="lg:relative">
              <StyleClass
                nodeRef={btnRef4}
                selector="@next"
                enterClassName="hidden"
                enterActiveClassName="scalein"
                leaveToClassName="hidden"
                leaveActiveClassName="fadeout"
                hideOnOutsideClick
              >
                <a
                  ref={btnRef4}
                  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round3 cursor-pointer transition-colors transition-duration-150 w-full"
                >
                  <i className="pi pi-users mr-2"></i>
                  <span>{t("MANAGEMENT")}</span>
                  <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                  <Ripple />
                </a>
              </StyleClass>
              <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round3 shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                <li>
                  <Link
                    to="/ChefiaPendentes"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-hourglass mr-2"></i>
                    <span className="font-medium">{t("PENDING_REQUESTS")}</span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ChefiaHistorico"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-history mr-2"></i>
                    <span className="font-medium">
                      {t("HISTORIC_REQUESTS")}
                    </span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/DelegarChefia"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-arrow-right mr-2"></i>
                    <span className="font-medium">
                      {t("DELEGATE_MANAGEMENT")}
                    </span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/FeriasTrabalhadores"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-sun mr-2"></i>
                    <span className="font-medium">{t("VACATION")}</span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Mapa"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-map mr-2"></i>
                    <span className="font-medium">{t("Mapa")}</span>
                    <Ripple />
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {import.meta.env.VITE_API_VERSAO === "2" && (
            <li className="lg:relative">
              <StyleClass
                nodeRef={btnRef5}
                selector="@next"
                enterClassName="hidden"
                enterActiveClassName="scalein"
                leaveToClassName="hidden"
                leaveActiveClassName="fadeout"
                hideOnOutsideClick
              >
                <a
                  ref={btnRef5}
                  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round3 cursor-pointer transition-colors transition-duration-150 w-full"
                >
                  <i className="pi pi-user mr-2"></i>
                  <span>{t("USER")}</span>
                  <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                  <Ripple />
                </a>
              </StyleClass>
              <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round3 shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                <li>
                  <Link
                    to="/faltas"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-calendar mr-2"></i>
                    <span className="font-medium">
                      {t("ABSENSES")} {t("VACATION")}
                    </span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/ponto"
                    className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round3 cursor-pointer transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-calendar-clock mr-2"></i>
                    <span className="font-medium">PONTO</span>
                    <Ripple />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/AlterarSenha"
                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-key mr-2"></i>
                    <span className="font-medium">{t("CHANGE_PASSWORD")}</span>
                    <Ripple />
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {import.meta.env.VITE_API_VERSAO === "1" &&
            (userRole === 1 || userRole === 0) && (
              <>
                <li>
                  <Link
                    to="/faltas"
                    className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round3 cursor-pointer transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-calendar mr-2"></i>
                    <span className="font-medium">
                      {t("ABSENSES")} {t("VACATION")}
                    </span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ponto"
                    className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round3 cursor-pointer transition-colors transition-duration-150 w-full"
                  >
                    <i className="pi pi-calendar-clock mr-2"></i>
                    <span className="font-medium">Ponto</span>
                    <Ripple />
                  </Link>
                </li>
              </>
            )}

          <li>
            <Link
              to="/login"
              className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round3 cursor-pointer transition-colors transition-duration-150 w-full"
            >
              {" "}
              <i className="pi pi-sign-out mr-2"></i>
              <span>Logout</span>
              <Ripple />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TopBar;
