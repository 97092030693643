/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import React, { useEffect, useRef, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Calendar } from "primereact/calendar";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ChurchIcon from "@mui/icons-material/Church";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AirlineSeatIndividualSuiteIcon from "@mui/icons-material/AirlineSeatIndividualSuite";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import CheckIcon from "@mui/icons-material/Check";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { InputText } from "primereact/inputtext";
import { locale } from "primereact/api";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

interface Trabalhador {
  id: number;
  trabalhador: string;
}

type Falta = {
  descricao: string;
  chefe_observacoes: string;
  safp_observacoes: string;
  [key: string]: string | number;
};

const PontoSAFP = () => {
  const [listaPontos, setPontos] = useState<Ponto[]>([]);
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage
  const { t } = useTranslation();
  locale(i18n.language);

  const [tipoSelecionado, setTipoSelecionado] = useState(-1);
  const [agruparSelecionado, setAgruparSelecionado] = useState(-1);

  const [dateI, setDateI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateF, setDateF] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateI2, setDateI2] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateF2, setDateF2] = useState<Date | undefined>(undefined); // Inicializado como undefined

  const [horaI, setHoraI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [horaF, setHoraF] = useState<Date | undefined>(undefined); // Inicial  const [horaF, setHoraF] = useState(null);

  useEffect(() => {
    //getPontoTrabalhadores();
    fetchUpcomingEventsData8();
  }, []);

  type Ponto = {
    estado: string;
    Inicio: string;
    Fim: string;
    tipo: string;

    [key: string]: string | number;
  };

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    setSelectedTrabalhador(null);
    setErrorMessage("");
    setDateI(undefined);
    setHoraI(undefined);
    setDateF(undefined);
    setHoraF(undefined);
    document.body.classList.add("no-scroll");
  };

  const clean2 = () => {
    setErrorMessage("");
    document.body.classList.add("no-scroll");
  };

  const [trabalhadores, setTrabalhadores] = useState<Trabalhador[]>([]);
  const [selectedTrabalhador, setSelectedTrabalhador] =
    useState<Trabalhador | null>(null);

  const [trabalhadores2, setTrabalhadores2] = useState<Trabalhador[]>([]);
  const [selectedTrabalhador2, setSelectedTrabalhador2] =
    useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData8() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadoresPonto",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setTrabalhadores(
          data.map((dado: { id_trabalhador: number; nome: string }) => ({
            id: dado.id_trabalhador,
            trabalhador: dado.nome,
          }))
        );

        setTrabalhadores2(
          data.map((dado: { id_trabalhador: number; nome: string }) => ({
            id: dado.id_trabalhador,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const getPontoTrabalhadores = async () => {
    try {
      if (token && csrfToken) {
        let tipo = -1;
        let agrupar = -1;
        let id_trabalhador = -1;
        let dataInicio = "";
        let dataFim = "";
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getPontoTrabalhadores",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language,
            },
            credentials: "include",
            body: JSON.stringify({
              tipo,
              id_trabalhador,
              dataInicio,
              dataFim,
              agrupar,
            }),
          }
        );

        const data = await response.json();

        if (data[0]?.Success === 0) {
          setPontos([]);
        } else {
          const pontosFormatados = data.result_recordset.map((ponto) => {
            // Função para formatar datas no formato dd/mm/yyyy e horas em hh:mm:ss
            const formatarDataHora = (dataHora) => {
              if (dataHora == null)
                return {
                  data: ` `,
                  hora: ` `,
                };
              const dataObj = new Date(dataHora);
              const dia = String(dataObj.getUTCDate()).padStart(2, "0");
              const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0");
              const ano = dataObj.getUTCFullYear();
              const horas = String(dataObj.getUTCHours()).padStart(2, "0");
              const minutos = String(dataObj.getUTCMinutes()).padStart(2, "0");
              const segundos = String(dataObj.getUTCSeconds()).padStart(2, "0");

              return {
                data: `${dia}/${mes}/${ano}`,
                hora: `${horas}:${minutos}:${segundos}`,
              };
            };

            // Função para converter delta para hh:mm
            const formatarDelta = (delta) => {
              const horas = Math.floor(delta);
              const minutos = Math.round((delta - horas) * 60);
              return `${String(horas).padStart(2, "0")}:${String(
                minutos
              ).padStart(2, "0")}`;
            };

            return {
              ...ponto,
              dataInicio: formatarDataHora(ponto.Inicio).data,
              horaInicio: formatarHoras(formatarDataHora(ponto.Inicio).hora),
              dataFim: ponto.Fim ? formatarDataHora(ponto.Fim).data : "",
              horaFim: ponto.Fim
                ? formatarHoras(formatarDataHora(ponto.Fim).hora)
                : "",
              delta: ponto.Fim ? formatarHoras(formatarDelta(ponto.delta)) : "",
              tipo:
                ponto.tipo === -1
                  ? "Sábado"
                  : ponto.tipo === -2
                  ? "Domingo"
                  : ponto.tipo === 0
                  ? ponto.motivo
                  : ponto.tipo === 1
                  ? ""
                  : ponto.tipo === 2
                  ? "Extra"
                  : ponto.tipo === 3
                  ? "Falta"
                  : ponto.tipo === 4
                  ? "Feriado"
                  : ponto.motivo,
              uniqueID: ` ${ponto.deltah}-${ponto.Inicio}-${ponto.Fim}`,
            };
          });

          setPontos(pontosFormatados);
        }
      }
    } catch (error) {
      console.error("Could not getPontoTrabalhadores", error);
    }
  };

  function formatarHoras(tempo) {
    // Verifica se a string está no formato esperado (hh:mm)
    if (typeof tempo === "string" && tempo.includes(":")) {
      const [horas, minutos] = tempo.split(":");
      return `${horas}h${minutos}min`;
    }
    return tempo;
  }

  const dhTemplate = (rowData) => {
    if (rowData.dh == 2) {
      return (
        <CheckIcon
          className="important-padding"
          sx={{
            color: "green",
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    } else if (rowData.dh == 1 || rowData.dh == 3) {
      return (
        <DangerousIcon
          className="important-padding"
          sx={{
            color: "red",
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    }

    return rowData.estado; // Retorna o valor original se não for "amarelo" ou "vermelho"
  };

  const dsTemplate = (rowData) => {
    if (rowData.ds > 2) {
      return (
        <CheckIcon
          className="important-padding"
          sx={{
            color: "green",
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    } else if (rowData.ds > 0 && rowData.ds <= 2) {
      return (
        <DangerousIcon
          className="important-padding"
          sx={{
            color: "red",
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    }

    return rowData.estado; // Retorna o valor original se não for "amarelo" ou "vermelho"
  };

  const estadoTemplate = (rowData) => {
    if (rowData.infim === true) {
      if (rowData.tipo === "Normal") {
        return (
          <CircleIcon
            className="important-padding"
            sx={{
              color: "#fff3cd",
              backgroundColor: "#fff3cd", // Cor de fundo (bordas amarelo claro)
              borderRadius: "50%",
              border: "2px solid #ffc107", // Borda de contorno
            }}
            style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
          />
        );
      } else {
        return (
          <CircleIcon
            className="important-padding"
            sx={{
              color: "#fff3cd",
              backgroundColor: "#fff3cd", // Cor de fundo (bordas amarelo claro)
              borderRadius: "50%",
              border: "2px solid rgb(255, 110, 7)", // Borda de contorno
            }}
            style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
          />
        );
      }
    } else if (rowData.infim === false) {
      if (rowData.tipo === "Extra") {
        return (
          <CircleIcon
            className="important-padding"
            sx={{
              color: "rgb(135, 205, 146)", // Cor do texto (branco)
              backgroundColor: "rgb(135, 205, 146)", // Cor do texto (branco)
              borderRadius: "50%", // Bordas arredondadas
              border: "2px solid rgb(16, 210, 48) ", // Borda de contorno (laranja avermelhado)
            }}
            style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
          />
        );
      } else if (rowData.tipo === "") {
        return (
          <CircleIcon
            className="important-padding"
            sx={{
              color: "rgb(208 , 234 ,229 )", // Cor do texto (branco)
              backgroundColor: "rgb(208 , 234 ,229 )", // Cor do texto (branco)
              borderRadius: "50%", // Bordas arredondadas
              border: "2px solid rgb(99, 185, 170 )", // Borda de contorno (laranja avermelhado)
            }}
            style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
          />
        );
      }
    } else if (rowData.infim === null) {
      if (rowData.tipo === "Falta") {
        return (
          <CircleIcon
            className="important-padding"
            sx={{
              color: "rgb(234, 208, 208)", // Cor do texto (branco)
              backgroundColor: "rgb(234, 208, 208)", // Cor do texto (branco)
              borderRadius: "50%", // Bordas arredondadas
              border: "2px solid  rgb(185, 99, 99)", // Borda de contorno (laranja avermelhado)
            }}
            style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
          />
        );
      } else if (rowData.tipo === "Feriado") {
        return (
          <CelebrationIcon
            className="important-padding"
            sx={{
              color: "rgb(212, 106, 1)", // Cor do texto (branco
            }}
            style={{ width: "1em", height: "1em", marginTop: "0.2em" }}
          />
        );
      } else if (rowData.tipo === "Férias") {
        return (
          <BeachAccessIcon
            className="important-padding"
            sx={{
              color: "rgb(230, 177, 4)", // Cor do ícone
            }}
            style={{
              width: "0.90em",
              height: "0.90em",
              marginTop: "0.2em",
              transform: "rotate(90deg)",
            }}
          />
        );
      } else if (rowData.tipo === "Doença") {
        return (
          <CoronavirusIcon
            className="important-padding"
            sx={{
              color: "rgb(174, 35, 141)", // Cor do ícone
            }}
            style={{
              width: "1em",
              height: "1em",
              marginTop: "0.2em",
            }}
          />
        );
      } else if (rowData.tipo === "Assist. Família") {
        return (
          <Diversity1Icon
            className="important-padding"
            sx={{
              color: "rgb(174, 35, 111)", // Cor do ícone
            }}
            style={{ width: "1em", height: "1em", marginTop: "0.2em" }}
          />
        );
      } else if (rowData.tipo === "Baixa") {
        return (
          <HealthAndSafetyIcon
            className="important-padding"
            sx={{
              color: "rgb(16, 210, 197)", // Cor do ícone
            }}
            style={{ width: "1em", height: "1em", marginTop: "0.2em" }}
          />
        );
      } else if (rowData.tipo === "Nojo(Luto)") {
        return (
          <ChurchIcon
            className="important-padding"
            sx={{
              color: "rgb(141, 150, 145)", // Cor do ícone
            }}
            style={{ width: "1em", height: "1em", marginTop: "0.2em" }}
          />
        );
      } else if (rowData.tipo === "Compensação") {
        return (
          <MoreTimeIcon
            className="important-padding"
            sx={{
              color: "rgb(36, 14, 202)", // Cor do ícone
            }}
            style={{ width: "1.2em", height: "1.2em", marginTop: "0.2em" }}
          />
        );
      } else if (rowData.tipo === "Sábado" || rowData.tipo === "Domingo") {
        return (
          <AirlineSeatIndividualSuiteIcon
            className="important-padding"
            sx={{
              color: "rgb(38, 37, 44)", // Cor do ícone
            }}
            style={{ width: "0.9em", height: "0.9em", marginTop: "0.2em" }}
          />
        );
      } else {
        return (
          <SettingsEthernetIcon
            className="important-padding"
            sx={{
              color: "rgb(139, 10, 225)", // Cor do ícone
            }}
            style={{ width: "1.2em", height: "1.2em", marginTop: "0.2em" }}
          />
        );
      }
    }

    return rowData.estado; // Retorna o valor original se não for "amarelo" ou "vermelho"
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const showInfoMessage = (message) => {
    toast.current?.show({
      severity: import.meta.env.VITE_API_SEVERITY,
      icon: "pi pi-search",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 1500,
    });
  };

  const [IsProcuras, setIsProcuras] = useState(false);

  const procura = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsProcuras) return;
    setIsProcuras(true);

    if (selectedTrabalhador2 == null || selectedTrabalhador2 == undefined) {
      setErrorMessage(t("SELECT_WORKER"));
      setIsProcuras(false);
      return;
    }

    if (dateI2 == null || dateI2 == undefined) {
      setIsProcuras(false);
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      return;
    }

    if (dateF2 == null || dateF2 == undefined) {
      setIsProcuras(false);
      setErrorMessage(t("SELECT_END_DATE"));
      return;
    }

    if (dateI2 && dateF2 && dateI2 > dateF2) {
      setErrorMessage("A data de início não pode ser superior à data de fim");
      setIsProcuras(false);
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/getPontoTrabalhadores",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: JSON.stringify({
            tipo: "",
            agrupar: agruparSelecionado || "",
            id_trabalhador: selectedTrabalhador2?.id || "",
            dataInicio: dateI2 ? dateI2.toISOString() : "",
            dataFim: dateF2 ? dateF2.toISOString() : "",
          }),
        }
      );

      const data = await response.json();

      if (data[0]?.Success === 0) {
        setPontos([]);
      } else {
        const pontosFormatados = data.result_recordset.map((ponto) => {
          // Função para formatar datas no formato dd/mm/yyyy e horas em hh:mm:ss
          const formatarDataHora = (dataHora) => {
            if (dataHora == null)
              return {
                data: ` `,
                hora: ` `,
              };

            const dataObj = new Date(dataHora);
            const dia = String(dataObj.getUTCDate()).padStart(2, "0");
            const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0");
            const ano = dataObj.getUTCFullYear();
            const horas = String(dataObj.getUTCHours()).padStart(2, "0");
            const minutos = String(dataObj.getUTCMinutes()).padStart(2, "0");
            const segundos = String(dataObj.getUTCSeconds()).padStart(2, "0");

            return {
              data: `${dia}/${mes}/${ano}`,
              hora: `${horas}:${minutos}:${segundos}`,
            };
          };

          // Função para converter delta para hh:mm
          const formatarDelta = (delta) => {
            if (delta == " ") return ` `;

            const horas = Math.floor(delta);
            const minutos = Math.round((delta - horas) * 60);
            return `${String(horas).padStart(2, "0")}:${String(
              minutos
            ).padStart(2, "0")}`;
          };

          return {
            ...ponto,
            dataInicio: formatarDataHora(ponto.Inicio).data,
            horaInicio:
              ponto.tipo <= -1
                ? ""
                : ponto.tipo == 0 && ponto.delta == "1 dia"
                ? ""
                : ponto.tipo < 3 || ponto.tipo == 5
                ? formatarHoras(formatarDataHora(ponto.Inicio).hora)
                : "",
            dataFim:
              ponto.tipo <= -1
                ? ""
                : ponto.tipo < 3 || ponto.tipo == 5
                ? formatarDataHora(ponto.Fim).data
                : "",
            horaFim:
              ponto.tipo <= -1
                ? ""
                : ponto.tipo == 0 && ponto.delta == "1 dia"
                ? ""
                : ponto.tipo < 3 || ponto.tipo == 5
                ? formatarHoras(formatarDataHora(ponto.Fim).hora)
                : "",
            delta:
              ponto.tipo <= -1
                ? ""
                : ponto.tipo == 0 || ponto.tipo == 5
                ? ponto.delta
                : ponto.tipo < 3
                ? formatarHoras(formatarDelta(ponto.delta))
                : "",
            tipo:
              ponto.tipo === -1
                ? "Sábado"
                : ponto.tipo === -2
                ? "Domingo"
                : ponto.tipo === 0
                ? ponto.motivo
                : ponto.tipo === 1
                ? ""
                : ponto.tipo === 2
                ? "Extra"
                : ponto.tipo === 3
                ? "Falta"
                : ponto.tipo === 4
                ? "Feriado"
                : ponto.motivo,
            uniqueID: ` ${ponto.deltah}-${ponto.Inicio}-${ponto.Fim}`,
          };
        });

        setPontos(pontosFormatados);
      }

      hideProcuras();
      setFirst(0);
    } catch (error) {
      console.error("Could not getPontoTrabalhadores", error);
    } finally {
      setIsProcuras(false);
    }
  };

  const [procuraDialog, setProcuraDialog] = useState(false);

  const openProcuras = () => {
    handleVacationButtonClick();
    clean2();
    setProcuraDialog(true);
  };

  const hideProcuras = () => {
    setProcuraDialog(false);

    close();
  };

  const ProcurasHeader = () => (
    <div>
      <i
        className="pi pi-search"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{t("SEARCH")}</label>
    </div>
  );

  const openAddPonto = () => {
    handleVacationButtonClick();
    clean();
    setHeader("Adicionar Ponto");
    setIcon("pi-circle");
    setAddPontoDialog(true);
  };

  const procuraDialogFooter = (
    <>
      <Button
        label={t("SEARCH2")}
        icon="pi pi-search"
        text
        className="   procurasbutton"
        onClick={procura}
      />
    </>
  );

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2   ">
          <Button
            label={"Ponto"}
            icon="pi pi-plus"
            className=" mr-2"
            onClick={openAddPonto}
            rounded
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />
          <Button
            label={t("SEARCH")}
            icon="pi pi-search "
            className=" mr-2 procurasbutton"
            onClick={openProcuras}
            rounded
          />

          <Button
            label={t("CLEAN")}
            icon="pi  pi-refresh"
            className=" mr-2 procurasbutton"
            onClick={reset}
            rounded
          />
        </div>
      </React.Fragment>
    );
  };

  const [first, setFirst] = useState(0); // Estado para controlar a primeira linha visível
  const [rows, setRows] = useState(50); // Estado para controlar o número de linhas por página

  const onPageChange = (e) => {
    setFirst(e.first); // Atualiza o estado da primeira linha visível
    setRows(e.rows); // Atualiza o estado do número de linhas por página
  };
  const reset = () => {
    setErrorMessage("");
    setTipoSelecionado(-1);
    setSelectedTrabalhador2(null);
    setDateI2(undefined);
    setDateF2(undefined);
    setFirst(0);
    setPontos([]);
    //getPontoTrabalhadores();
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    if (rowData.movimento === selectedRow) {
      return "highlightBlack";
    }

    if (rowData.tipo === "Falta") {
      return "highlightRed";
    } else if (rowData.tipo === "Férias") {
      return "highlightYellow";
    } else if (rowData.tipo === "Compensação") {
      return "highlightBlue";
    } else if (rowData.tipo === "Doença") {
      return "highlightPurple";
    } else if (rowData.tipo === "Extra") {
      return "highlightGreen";
    } else if (rowData.tipo === "Assist. Família") {
      return "highlightRed2";
    } else if (rowData.tipo === "Nojo(Luto)") {
      return "highlightGray";
    } else if (rowData.tipo === "Baixa") {
      return "highlightGreen2";
    } else if (
      rowData.tipo != "" &&
      rowData.tipo != "Sábado" &&
      rowData.tipo != "Domingo" &&
      rowData.tipo != "Feriado"
    ) {
      return "highlightPurple2";
    }
  };

  const editarFalta = (rowData) => {
    if (rowData.tipo === "" || rowData.tipo === "Extra") {
      return (
        <>
          <Button
            icon="pi pi-pencil"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => editFalta(rowData)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const eliminarFalta = (rowData) => {
    if (rowData.tipo === "" || rowData.tipo === "Extra") {
      return (
        <>
          <Button
            icon="pi pi-trash"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => deleteFalta(rowData)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const showNiceMessage2 = (message) => {
    toast.current?.show({
      severity: "success",
      icon: "pi pi-trash",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteRow = async (movimento) => {
    if (!csrfToken) return;
    if (!token) return;

    if (movimento == null || movimento == undefined) return;

    if (isDeleting) return;
    setIsDeleting(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/deletePonto",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ movimento }),
        }
      );

      if (response.ok) {
        procura();
        showNiceMessage2(t("DELETE_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("DELETE_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("DELETE_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const [editPontoDialog, setEditPontoDialog] = useState(false);
  const [addPontoDialog, setAddPontoDialog] = useState(false);

  const deleteFalta = (rowData) => {
    setSelectedRow(rowData.movimento);

    confirmDialog({
      message: t("DELETE_CONFIRMATION"),
    });
  };

  const editFalta = (rowData) => {
    document.body.classList.add("no-scroll");

    if (rowData?.dataInicio) {
      const dateParts = rowData.dataInicio.split("/"); // Divide a string em partes: dia, mês, ano
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`; // Formata para "mm/dd/yyyy"
      const dateObj = new Date(formattedDate); // Cria um objeto Date a partir da string formatada
      setDateI(dateObj); // Define a data de início como valor inicial do calendário
    }
    if (rowData?.dataFim) {
      const dateParts = rowData.dataFim.split("/"); // Divide a string em partes: dia, mês, ano
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`; // Formata para "mm/dd/yyyy"
      const dateObj = new Date(formattedDate); // Cria um objeto Date a partir da string formatada
      setDateF(dateObj); // Define a data de início como valor inicial do calendário
    }

    if (rowData.horaInicio) {
      const horaInicioObj = parseTimeToDateTime(rowData.horaInicio); // Converte horaInicio para Date
      setHoraI(horaInicioObj); // Define a hora de início como valor inicial do calendário
    }
    if (rowData.horaFim) {
      const horaFimObj = parseTimeToDateTime(rowData.horaFim); // Converte horaFim para Date
      setHoraF(horaFimObj); // Define a hora de fim como valor inicial do calendário
    }

    if (rowData.Trabalhador) setSelectedTrabalhador(rowData.Trabalhador);
    else setSelectedTrabalhador(null);

    setFalta(rowData);
    if (rowData.tipo == "") setTipoSelecionado(1);
    else if (rowData.tipo == "Extra") setTipoSelecionado(2);
    handleVacationButtonClick();
    setIcon("pi-pencil");
    setHeader("Editar Ponto");
    setSelectedRow(rowData.movimento);
    setErrorMessage("");
    setEditPontoDialog(true);
  };

  const [isAddPonto, setIsAddPonto] = useState(false);

  const addPonto = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (isAddPonto) return;
    setIsAddPonto(true);

    if (selectedTrabalhador == null || selectedTrabalhador == undefined) {
      setIsAddPonto(false);
      setErrorMessage(t("SELECT_WORKER"));
      return;
    }

    if (dateI == null || dateI == undefined) {
      setIsAddPonto(false);
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      return;
    }

    if (horaI == null || horaI == undefined) {
      setIsAddPonto(false);
      setErrorMessage(t("SELECT_BEGIN_HOUR"));
      return;
    }

    if (dateF == null || dateF == undefined) {
      setIsAddPonto(false);
      setErrorMessage(t("SELECT_END_DATE"));
      return;
    }

    if (horaF == null || horaF == undefined) {
      setIsAddPonto(false);
      setErrorMessage(t("SELECT_END_HOUR"));
      return;
    }

    let horaInicial = horaI
      ? horaI.toLocaleTimeString("pt-PT", {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "00:00";
    let horaFinal = horaF
      ? horaF.toLocaleTimeString("pt-PT", {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "00:00";

    if (formatDate(dateI) === formatDate(dateF) && horaFinal < horaInicial) {
      setErrorMessage("A hora de fim não pode ser anterior à hora de início.");
      setIsAddPonto(false);
      return;
    }

    if (
      tipoSelecionado == null ||
      tipoSelecionado == undefined ||
      tipoSelecionado == -1
    ) {
      setIsAddPonto(false);
      setErrorMessage("Selecione um tipo");
      return;
    }

    try {
      const response = await fetch(import.meta.env.VITE_API_URL + "/addPonto", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
          "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
        },
        credentials: "include",
        body: JSON.stringify({
          tipo: tipoSelecionado || "",
          trabalhador: selectedTrabalhador.id,
          inicio_data: formatDate(dateI),
          fim_data: formatDate(dateF),
          inicio_hora: horaInicial,
          fim_hora: horaFinal,
        }),
      });

      if (response.ok) {
        procura();
        hideAddPonto();
        //showNiceMessage(t("EDIT_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("EDIT_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("EDIT_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsAddPonto(false);
    }
  };

  const [IsEditPonto, setIsEditPonto] = useState(false);

  const editPonto = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsEditPonto) return;
    setIsEditPonto(true);

    if (isNaN(parseInt(selectedRow, 10))) {
      setIsEditPonto(false);
      setErrorMessage("Something wrong with ID");
      return;
    }

    if (dateI == null || dateI == undefined) {
      setIsEditPonto(false);
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      return;
    }

    if (dateF == null || dateF == undefined) {
      setIsEditPonto(false);
      setErrorMessage(t("SELECT_END_DATE"));
      return;
    }

    if (horaI == null || horaI == undefined) {
      setIsEditPonto(false);
      setErrorMessage(t("SELECT_BEGIN_HOUR"));
      return;
    }

    if (horaF == null || horaF == undefined) {
      setIsEditPonto(false);
      setErrorMessage(t("SELECT_END_HOUR"));
      return;
    }

    let horaInicial = horaI
      ? horaI.toLocaleTimeString("pt-PT", {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "00:00";
    let horaFinal = horaF
      ? horaF.toLocaleTimeString("pt-PT", {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "00:00";

    if (formatDate(dateI) === formatDate(dateF) && horaFinal < horaInicial) {
      setErrorMessage("A hora de fim não pode ser anterior à hora de início.");
      setIsEditPonto(false);
      return;
    }

    if (
      tipoSelecionado == null ||
      tipoSelecionado == undefined ||
      tipoSelecionado == -1
    ) {
      setIsAddPonto(false);
      setIsEditPonto(false);
      setErrorMessage(t("SELECT_TYPE"));
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/editPonto",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: JSON.stringify({
            tipo: tipoSelecionado || "",
            mov: selectedRow,
            trabalhador: selectedTrabalhador,
            inicio_data: formatDate(dateI),
            fim_data: formatDate(dateF),
            inicio_hora: horaInicial,
            fim_hora: horaFinal,
          }),
        }
      );

      if (response.ok) {
        procura();
        hideEditPonto();
        //showNiceMessage(t("EDIT_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("EDIT_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("EDIT_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsEditPonto(false);
    }
  };

  const editHorasPonto = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={editPonto}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const addPontoFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={addPonto}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const hideEditPonto = () => {
    setEditPontoDialog(false);
    close();
  };

  const hideAddPonto = () => {
    setAddPontoDialog(false);
    close();
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if isoString is undefined or null
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0"); // Pad single-digit days with a leading zero

    // Return formatted date
    return `${year}-${month}-${day}`;
  };

  const [icon, setIcon] = useState("");
  const [header, setHeader] = useState("");

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const parseTimeToDateTime = (timeStr: string): Date => {
    const today = new Date(); // Obtém a data atual
    const match = timeStr.match(/(\d{1,2})h(\d{2})min/); // Extrai horas e minutos do formato "hhh:mmm"

    if (!match) {
      console.error("Formato inválido:", timeStr);
      return today; // Retorna a data atual sem alterações para evitar erros
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);

    today.setHours(hours, minutes, 0, 0); // Define a hora correta
    return today;
  };

  const [infoDialog, setInfoDialog] = useState(false);
  const [falta, setFalta] = useState<Falta | null>(null); // Exemplo de inicialização com null

  const InfoHeader = () => (
    <div>
      <i
        className="pi pi-info-circle"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{t("ADDITIONAL_INFO")}</label>
    </div>
  );

  const InfoFooter = <></>;

  const hideInfo = () => {
    setInfoDialog(false);
    close();
  };

  const info = (rowData) => {
    if (rowData.motivo == "Compensação") {
      return (
        <>
          <Button
            icon="pi pi-info-circle"
            size="small"
            rounded
            className="  pencil"
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => openInfo(rowData)}
          />
        </>
      );
    }
  };

  const openInfo = (rowData) => {
    setFalta(rowData);
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setSelectedRow(rowData.movimento);

    setInfoDialog(true);
  };

  // Função para alternar agruparSelecionado e chamar a função procura
  const handleClick = () => {
    setAgruparSelecionado((prev) => (prev === -1 ? 1 : -1)); // Atualiza o estado corretamente
  };

  useEffect(() => {
    procura(); // Só chama procura quando agruparSelecionado muda
  }, [agruparSelecionado]); // Executa sempre que agruparSelecionado muda

  const currentPageTemplate = `{currentPage} ${t("OF2")} {totalPages}`;

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-3 botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>
          <Button
            label={agruparSelecionado === -1 ? "Agrupar" : "Desagrupar"} // Altera a label conforme o valor de agruparSelecionado
            icon={
              agruparSelecionado === -1 ? "pi pi-table" : " pi  pi-th-large"
            }
            className="fixed-button"
            onClick={handleClick}
          />

          <DataTable
            ref={dt}
            value={listaPontos}
            dataKey="uniqueID"
            paginator
            rows={rows}
            rowsPerPageOptions={[5, 10, 35, 50, 75, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={currentPageTemplate}
            emptyMessage="Nenhum movimento encontrado"
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            first={first} // Atributo para controlar a primeira linha visível
            onPage={onPageChange} // Atualiza o estado quando a página muda
          >
            {agruparSelecionado === -1 && (
              <Column
                body={eliminarFalta}
                style={{
                  minWidth: "2rem",
                  textAlign: "center",
                }}
              />
            )}
            {agruparSelecionado === -1 && (
              <Column
                body={editarFalta}
                style={{
                  minWidth: "2rem",
                  textAlign: "center",
                }}
              />
            )}

            <Column
              field="Nome"
              header={t("WORKER")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="dataInicio"
              header={t("BEGIN_DATE")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="horaInicio"
              header={t("BEGIN_HOUR")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="dataFim"
              header={t("END_DATE")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="horaFim"
              header={t("END_HOUR")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="delta"
              header={t("TIME")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              className="important-padding"
              body={estadoTemplate}
              style={{
                maxWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
            <Column
              field="tipo"
              header={t("TYPE")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="DH"
              body={dhTemplate}
              header={t("D.H.")}
              style={{ maxWidth: "2rem" }}
            ></Column>
            <Column
              field="DS"
              body={dsTemplate}
              header={t("D.S.")}
              style={{ maxWidth: "2rem" }}
            ></Column>
            <Column
              body={info}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
          </DataTable>

          <Dialog
            visible={procuraDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<ProcurasHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={procuraDialogFooter}
            onHide={hideProcuras}
            dismissableMask={true}
          >
            <div className="field ">
              <label
                htmlFor="Trabalhador"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("WORKER")}
              </label>
              <Dropdown
                value={selectedTrabalhador2}
                onChange={(e) => setSelectedTrabalhador2(e.value)}
                options={trabalhadores2}
                optionLabel="trabalhador"
                placeholder={t("OBRIGATORIO")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataInicio"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI2}
                onChange={(e) => setDateI2(e.value)}
                dateFormat="dd/mm/yy"
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataFim"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("END_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF2}
                onChange={(e) => setDateF2(e.value)}
                dateFormat="dd/mm/yy"
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            {/* <div className="field">
              <label
                htmlFor="tipo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("Tipo")}
              </label>
              <Dropdown
                id="tipo"
                value={tipoSelecionado}
                options={[
                  { label: "Todos", value: -1 },
                  { label: "Normal", value: 1 },
                  { label: "Extra", value: 2 },
                ]}
                onChange={(e) => setTipoSelecionado(e.value)}
                placeholder="opcional"
                className="w-full motivo-dialog procura-dialog md:w-19rem"
              />
            </div> */}

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>
          </Dialog>

          <Dialog
            visible={infoDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<InfoHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={InfoFooter}
            onHide={hideInfo}
            dismissableMask={true} // Allow clicking outside to close the dialog
          >
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.descricao}
              />
            </div>

            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("BOSS_OBSERVATIONS")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.chefe_observacoes}
              />
            </div>

            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("SAFP_OBSERVATIONS")}{" "}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.safp_observacoes}
              />
            </div>
          </Dialog>

          <Dialog
            visible={editPontoDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editHorasPonto}
            onHide={hideEditPonto}
            dismissableMask={true} // Allow clicking outside to close the dialog
          >
            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_HOUR")}
              </label>
              <Calendar
                value={horaI}
                onChange={(e) => setHoraI(e.value)}
                timeOnly
              />
            </div>
            <div className="field">
              <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                {t("END_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaFim" style={{ fontSize: "0.9rem" }}>
                {t("END_HOUR")}
              </label>
              <Calendar
                value={horaF}
                onChange={(e) => setHoraF(e.value)}
                timeOnly
              />
            </div>

            <div className="field">
              <label
                htmlFor="tipo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("Tipo")}
              </label>
              <Dropdown
                id="tipo"
                value={tipoSelecionado}
                options={[
                  { label: "Normal", value: 1 },
                  { label: "Extra", value: 2 },
                ]}
                onChange={(e) => setTipoSelecionado(e.value)}
              />
            </div>
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>
          </Dialog>

          <Dialog
            visible={addPontoDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={addPontoFooter}
            onHide={hideAddPonto}
            dismissableMask={true} // Allow clicking outside to close the dialog
          >
            <div className="field ">
              <label
                htmlFor="Trabalhador"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("WORKER")}
              </label>
              <Dropdown
                value={selectedTrabalhador}
                onChange={(e) => setSelectedTrabalhador(e.value)}
                options={trabalhadores}
                optionLabel="trabalhador"
              />
            </div>

            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_HOUR")}
              </label>
              <Calendar
                value={horaI}
                onChange={(e) => setHoraI(e.value)}
                timeOnly
              />
            </div>
            <div className="field">
              <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                {t("END_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaFim" style={{ fontSize: "0.9rem" }}>
                {t("END_HOUR")}
              </label>
              <Calendar
                value={horaF}
                onChange={(e) => setHoraF(e.value)}
                timeOnly
              />
            </div>

            <div className="field">
              <label
                htmlFor="tipo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("Tipo")}
              </label>
              <Dropdown
                id="tipo"
                value={tipoSelecionado}
                options={[
                  { label: "Normal", value: 1 },
                  { label: "Extra", value: 2 },
                ]}
                onChange={(e) => setTipoSelecionado(e.value)}
              />
            </div>

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil pencil2"
                    label={t("CANCEL")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  ></Button>
                  <Button
                    label={t("YES")}
                    text
                    onClick={(event) => {
                      hide(event);
                      deleteRow(selectedRow);
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default PontoSAFP;
