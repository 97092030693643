/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import React, { useEffect, useRef, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";

const Ponto = () => {
  const [listaPontos, setPontos] = useState<Ponto[]>([]);
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage
  const { t } = useTranslation();
  const [currentMonthYear, setCurrentMonthYear] = useState<string>("");
  const [currentMonth, setCurrentMonth] = useState<string>("");
  const [tipoSelecionado, setTipoSelecionado] = useState(null);

  const [horasDia, setHorasDia] = useState<string>("");
  const [horasSemana, setHorasSemana] = useState<string>("");
  const [horasMes, setHorasMes] = useState<string>("");

  const meses = [
    { label: "Janeiro", value: 1 },
    { label: "Fevereiro", value: 2 },
    { label: "Março", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Maio", value: 5 },
    { label: "Junho", value: 6 },
    { label: "Julho", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Setembro", value: 9 },
    { label: "Outubro", value: 10 },
    { label: "Novembro", value: 11 },
    { label: "Dezembro", value: 12 },
  ];

  function getMesLabel(valor) {
    const mes = meses.find((m) => m.value === valor);
    return mes ? mes.label : "Mês não encontrado";
  }

  const anoAtual = new Date().getFullYear();
  const anos = [
    { label: anoAtual.toString(), value: anoAtual },
    { label: (anoAtual - 1).toString(), value: anoAtual - 1 },
  ];
  const [mesSelecionado, setMesSelecionado] = useState(null);
  const [anoSelecionado, setAnoSelecionado] = useState(anoAtual);

  useEffect(() => {
    getPontoRegistros();
  }, []);

  type Ponto = {
    estado: string;
    Inicio: string;
    Fim: string;
    tipo: string;

    [key: string]: string | number;
  };

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    setErrorMessage("");
    setMesSelecionado(null);
    setAnoSelecionado(anoAtual);
    setTipoSelecionado(null);
    document.body.classList.add("no-scroll");
  };

  const getPontoRegistros = async () => {
    const currentDate = new Date();
    let ano = currentDate.getFullYear();
    let mes = currentDate.getMonth() + 1; // getMonth() retorna um índice de 0 a 11, por isso somamos 1

    const dia = currentDate.getDate();
    try {
      if (token && csrfToken) {
        // Se for dia 21 ou superior, avançamos para o próximo mês
        if (dia >= 21) {
          mes += 1;
          if (mes > 12) {
            // Se o mês for maior que 12, avançamos o ano
            mes = 1;
            ano += 1;
          }
        }

        let tipo = 0;
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getPontoRegistros",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language,
            },
            credentials: "include",
            body: JSON.stringify({ ano, mes, tipo }),
          }
        );

        const data = await response.json();

        if (data[0]?.Success === 0) {
          setPontos([]);
          setHorasDia("00h00min");
          setHorasSemana("00h00min");
          setHorasMes("00h00min");
        } else {
          const pontosFormatados = data.result_recordset.map((ponto) => {
            // Função para formatar datas no formato dd/mm/yyyy e horas em hh:mm:ss
            const formatarDataHora = (dataHora) => {
              const dataObj = new Date(dataHora);
              const dia = String(dataObj.getUTCDate()).padStart(2, "0");
              const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0");
              const ano = dataObj.getUTCFullYear();
              const horas = String(dataObj.getUTCHours()).padStart(2, "0");
              const minutos = String(dataObj.getUTCMinutes()).padStart(2, "0");
              const segundos = String(dataObj.getUTCSeconds()).padStart(2, "0");

              return {
                data: `${dia}/${mes}/${ano}`,
                hora: `${horas}:${minutos}:${segundos}`,
              };
            };

            // Função para converter delta para hh:mm
            const formatarDelta = (delta) => {
              const horas = Math.floor(delta);
              const minutos = Math.round((delta - horas) * 60);
              return `${String(horas).padStart(2, "0")}:${String(
                minutos
              ).padStart(2, "0")}`;
            };

            return {
              ...ponto,
              dataInicio: formatarDataHora(ponto.Inicio).data,
              horaInicio: formatarHoras(formatarDataHora(ponto.Inicio).hora),
              dataFim: ponto.Fim ? formatarDataHora(ponto.Fim).data : "",
              horaFim: ponto.Fim
                ? formatarHoras(formatarDataHora(ponto.Fim).hora)
                : "",
              delta: ponto.Fim ? formatarHoras(formatarDelta(ponto.delta)) : "",
              tipo:
                ponto.tipo === 1
                  ? "Normal"
                  : ponto.tipo === 2
                  ? "Extra"
                  : ponto.tipo,
            };
          });

          setPontos(pontosFormatados);
          setHorasDia(formatarHoras(String(data.horas_dia)));
          setHorasSemana(formatarHoras(String(data.horas_semana)));
          setHorasMes(formatarHoras(String(data.horas_mes)));
        }
      }
      const dateOptions = { month: "long", year: "numeric" };

      const rawMonthYear = new Date(ano, mes - 1).toLocaleDateString(
        "pt-PT",
        dateOptions
      );

      setCurrentMonthYear(
        rawMonthYear.charAt(0).toUpperCase() + rawMonthYear.slice(1)
      );

      setCurrentMonth(getPeriodoMesAtual(mes));
    } catch (error) {
      console.error("Could not getPontoRegistros", error);
    }
  };

  function formatarHoras(tempo) {
    // Verifica se a string está no formato esperado (hh:mm)
    if (typeof tempo === "string" && tempo.includes(":")) {
      const [horas, minutos] = tempo.split(":");
      return `${horas}h${minutos}min`;
    }
    return tempo;
  }

  const estadoTemplate = (rowData) => {
    if (rowData.infim === true) {
      return (
        <CircleIcon
          className="important-padding"
          sx={{
            color: "#fff3cd",
            backgroundColor: "#fff3cd", // Cor de fundo (bordas amarelo claro)
            borderRadius: "50%",
            border: "2px solid #ffc107", // Borda de contorno
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    } else if (rowData.infim === false) {
      return (
        <CircleIcon
          className="important-padding"
          sx={{
            color: "rgb(208 , 234 ,229 )", // Cor do texto (branco)
            backgroundColor: "rgb(208 , 234 ,229 )", // Cor do texto (branco)
            borderRadius: "50%", // Bordas arredondadas
            border: "2px solid rgb(99, 185, 170 )", // Borda de contorno (laranja avermelhado)
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    }
    return rowData.estado; // Retorna o valor original se não for "amarelo" ou "vermelho"
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const showInfoMessage = (message) => {
    toast.current?.show({
      severity: import.meta.env.VITE_API_SEVERITY,
      icon: "pi pi-search",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 1500,
    });
  };

  const [IsProcuras, setIsProcuras] = useState(false);

  const procura = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsProcuras) return;
    setIsProcuras(true);

    // Garantir que mes e ano não sejam vazios ou null
    if (!mesSelecionado) {
      setErrorMessage("O campo Mês é obrigatório.");
      setIsProcuras(false);
      return;
    }

    if (!anoSelecionado) {
      setErrorMessage("O campo Mês é obrigatório.");
      console.error("O campo Mês é obrigatório.");
      setIsProcuras(false);
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/getPontoRegistros",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: JSON.stringify({
            ano: anoSelecionado || "",
            mes: mesSelecionado || "",
            tipo: tipoSelecionado || "",
          }),
        }
      );

      const data = await response.json();

      if (data[0]?.Success === 0) {
        setPontos([]);
        setHorasDia("00h00min");
        setHorasSemana("00h00min");
        setHorasMes("00h00min");
      } else {
        const pontosFormatados = data.result_recordset.map((ponto) => {
          // Função para formatar datas no formato dd/mm/yyyy e horas em hh:mm:ss
          const formatarDataHora = (dataHora) => {
            const dataObj = new Date(dataHora);
            const dia = String(dataObj.getUTCDate()).padStart(2, "0");
            const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0");
            const ano = dataObj.getUTCFullYear();
            const horas = String(dataObj.getUTCHours()).padStart(2, "0");
            const minutos = String(dataObj.getUTCMinutes()).padStart(2, "0");
            const segundos = String(dataObj.getUTCSeconds()).padStart(2, "0");

            return {
              data: `${dia}/${mes}/${ano}`,
              hora: `${horas}:${minutos}:${segundos}`,
            };
          };

          // Função para converter delta para hh:mm
          const formatarDelta = (delta) => {
            const horas = Math.floor(delta);
            const minutos = Math.round((delta - horas) * 60);
            return `${String(horas).padStart(2, "0")}:${String(
              minutos
            ).padStart(2, "0")}`;
          };

          return {
            ...ponto,
            dataInicio: formatarDataHora(ponto.Inicio).data,
            horaInicio: formatarHoras(formatarDataHora(ponto.Inicio).hora),
            dataFim: ponto.Fim ? formatarDataHora(ponto.Fim).data : "",
            horaFim: ponto.Fim
              ? formatarHoras(formatarDataHora(ponto.Fim).hora)
              : "",
            delta: ponto.Fim ? formatarHoras(formatarDelta(ponto.delta)) : "",
            tipo:
              ponto.tipo === 1
                ? "Normal"
                : ponto.tipo === 2
                ? "Extra"
                : ponto.tipo,
          };
        });
        setPontos(pontosFormatados);
        setHorasDia(formatarHoras(String(data.horas_dia)));
        setHorasSemana(formatarHoras(String(data.horas_semana)));
        setHorasMes(formatarHoras(String(data.horas_mes)));
      }

      hideProcuras();
      setFirst(0);
      showInfoMessage(t("SEARCH_INFO"));
    } catch (error) {
      console.error("Could not getPontoRegistros", error);
    } finally {
      setIsProcuras(false);
      setCurrentMonthYear(
        getMesLabel(mesSelecionado) + " de " + anoSelecionado
      );
      setCurrentMonth(getPeriodoMesAtual(parseInt(mesSelecionado)));
    }
  };

  function getPeriodoMesAtual(mes: number): string {
    const mesesCompletos = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const mesAnterior = mes === 1 ? 12 : mes - 1;
    const mesAtual = mes;

    return `(de 21 de ${mesesCompletos[mesAnterior - 1]} a 20 de ${
      mesesCompletos[mesAtual - 1]
    })`;
  }

  const [procuraDialog, setProcuraDialog] = useState(false);

  const openProcuras = () => {
    handleVacationButtonClick();
    clean();
    setProcuraDialog(true);
  };

  const hideProcuras = () => {
    setProcuraDialog(false);

    close();
  };

  const ProcurasHeader = () => (
    <div>
      <i
        className="pi pi-search"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{t("SEARCH")}</label>
    </div>
  );

  const procuraDialogFooter = (
    <>
      <Button
        label={t("SEARCH2")}
        icon="pi pi-search"
        text
        className="   procurasbutton"
        onClick={procura}
      />
    </>
  );

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2   ">
          <Button
            label={t("SEARCH")}
            icon="pi pi-search
                      "
            className=" mr-2 procurasbutton"
            onClick={openProcuras}
            rounded
          />

          <Button
            label={t("CLEAN")}
            icon="pi  pi-refresh"
            className=" mr-2 procurasbutton"
            onClick={reset}
            rounded
          />
        </div>
      </React.Fragment>
    );
  };

  const leftToolbarTemplate2 = () => {
    return (
      <React.Fragment>
        <div className="my-2 toolbar">
          <div>
            {" "}
            {/* Adicionado alignItems: "center" */}
            <span
              style={{
                fontSize: "1.5em",
                padding: "0rem 5rem 0rem 0.7rem",
                // alignSelf: "flex-start", // Adicionado alignSelf: "flex-start" para alinhar o primeiro span à esquerda
              }}
            >
              <strong style={{ fontWeight: "bolder" }}>
                {" "}
                {currentMonthYear}
              </strong>
              <span style={{ fontSize: "0.7em", marginLeft: "0.5rem" }}>
                {currentMonth}
              </span>
            </span>
          </div>
          <div className="toolbar-desktop">
            <span style={{ padding: "0 2rem" }}>
              <strong style={{ fontWeight: "bold" }}>
                {" "}
                {t("Total Horas do dia")}:
              </strong>{" "}
              {horasDia}
            </span>

            <span style={{ padding: "0 2rem" }}>
              <strong style={{ fontWeight: "bold" }}>
                {" "}
                {t("Total Horas da Semana")}:
              </strong>{" "}
              {horasSemana}
            </span>

            <span style={{ padding: "0 2rem" }}>
              <strong style={{ fontWeight: "bold" }}>
                {t("Total Horas do Mês")}:
              </strong>{" "}
              {horasMes}
            </span>

            {/* <span style={{ padding: "0 0.5rem" }}>
                <strong>{t("MEAL")}:</strong> {inicioRefeicao} {fimRefeicao}
              </span> */}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const [first, setFirst] = useState(0); // Estado para controlar a primeira linha visível
  const [rows, setRows] = useState(10); // Estado para controlar o número de linhas por página

  const onPageChange = (e) => {
    setFirst(e.first); // Atualiza o estado da primeira linha visível
    setRows(e.rows); // Atualiza o estado do número de linhas por página
  };
  const reset = () => {
    setFirst(0);
    getPontoRegistros();
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    return rowData.id === selectedRow ? "highlight" : "";
  };

  const currentPageTemplate = `{currentPage} ${t("OF2")} {totalPages}`;

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-3 datatable-transition"
            style={{ padding: "0.2rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate2} /* right={rightToolbarTemplate} */
          ></Toolbar>

          <Toolbar
            className="mb-3 botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={listaPontos}
            dataKey="movimento"
            paginator
            rows={rows}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={currentPageTemplate}
            emptyMessage="Nenhum movimento encontrado"
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
            first={first} // Atributo para controlar a primeira linha visível
            onPage={onPageChange} // Atualiza o estado quando a página muda
          >
            <Column
              className="important-padding"
              body={estadoTemplate}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
            <Column
              field="dataInicio"
              header={t("BEGIN_DATE")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="horaInicio"
              header={t("BEGIN_HOUR")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="dataFim"
              header={t("END_DATE")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="horaFim"
              header={t("END_HOUR")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="delta"
              header={t("TIME")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="tipo"
              header={t("TYPE")}
              style={{ minWidth: "5rem" }}
            ></Column>
          </DataTable>

          <Dialog
            visible={procuraDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<ProcurasHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={procuraDialogFooter}
            onHide={hideProcuras}
            dismissableMask={true}
          >
            <div className="field">
              <label
                htmlFor="mes"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("MONTH")}
              </label>
              <Dropdown
                id="mes"
                value={mesSelecionado}
                options={meses}
                onChange={(e) => setMesSelecionado(e.value)}
                placeholder="Selecione um mês"
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>
            <div className="field">
              <label
                htmlFor="ano"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("Ano")}
              </label>
              <Dropdown
                id="ano"
                value={anoSelecionado}
                options={anos}
                onChange={(e) => setAnoSelecionado(e.value)}
                placeholder="Selecione um ano"
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>
            <div className="field">
              <label
                htmlFor="tipo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("Tipo")}
              </label>
              <Dropdown
                id="tipo"
                value={tipoSelecionado}
                options={[
                  { label: "Normal", value: 1 },
                  { label: "Extra", value: 2 },
                ]}
                onChange={(e) => setTipoSelecionado(e.value)}
                placeholder="opcional"
                className="w-full motivo-dialog procura-dialog md:w-19rem"
              />
            </div>
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>
          </Dialog>
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default Ponto;
